@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.container {
  margin-top: 16px;
}

.formContainer {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
}