.orloDirect {
    color: white;
    background-image: linear-gradient(
      42deg,
      #2473b4 0%,
      #32b5ee 100%
    );
  
    margin: auto 0;
    padding: 4px 12px;
    border-radius: 24px;
  
    font-size: 14px;
    text-decoration: none;
    text-align: center;
  
    >b {
      letter-spacing: -1px;
    }
  }