.root {
  stroke: var(--marketplaceColorLight);
  fill: var(--marketplaceColorLight);

  height: 12px;
  width: 12px;

  @media (--viewportLarge) {
    height: 15px;
    width: 15px;
  }
}
