@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: white;
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin: 16px 0 4px 0;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);
  color: black;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 13px;
  font-weight: 500;
  color: var(--colorGrey700);
  margin: 0 0 4px 0;
  line-height: normal;
}

.manufacturer {
  font-size: 16px;
  font-weight: 700;
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  font-size: 12px;
  font-weight: 400;
  color: var(--marketplaceColorLight);
  margin: 0 0 8px 0;
  line-height: normal;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.tagContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 4px;

  position: absolute;
  top: 8px;
  right: 4px;
}

.tag {
  padding: 2px 12px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 16px;
}

.request {
  background-color: rgb(180, 245, 243);
  border: 0.5px solid rgb(0, 245, 243);
  color: black;
}

/* .sell {
  background-color: #f0e784;
  color: black;
} */

.new {
  background-color: rgb(204, 232, 247);
  border: 0.5px solid rgb(0, 166, 255);
  color: black;
}

.used {
  background-color: rgb(247, 240, 201);
  border: 0.5px solid rgb(255, 217, 0);
  color: black;
}

/* .nonOperational {
  background-color: rgb(223, 65, 65);
  color: white;
} */